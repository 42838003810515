import {useLogger, useNuxtApp} from '#imports';

export function getCurrentServerName() {
    const nuxtApp = useNuxtApp();
    const logger = useLogger();
    let host = '';

    // Within Nuxt, it's possible to run on server side or client side
    // depending on that, the host has to be retrieved either from window.location in the browser
    // or from the user request from the server
    if (import.meta.server) {
        if (!nuxtApp.ssrContext?.event.node.req) {
            logger.warn('No request in SSR context ?!', nuxtApp.ssrContext, nuxtApp.ssrContext?.req);
        } else {
            host = nuxtApp.ssrContext.event.node.req.headers?.origin ?? '';
        }
    } else {
        host = window.location.origin;
    }
    return host;
}
