import {defineNuxtRouteMiddleware} from '#imports';
import {useCheckSessionAndIdentity} from '../composables/useCheckSessionAndIdentity';

/**
 * Here, we rely on the store to check if the user is logged in and allow them to navigate to the
 * destination page.
 * A page will have to include this middleware to protect it from unauthorized access.
 *
 * This middleware also takes care of enforcing a user identity, and of redirecting the user elsewhere
 * in case some workflow is in progress, for example in case a client signup.
 * Note that redirection to a workflow in progress can also happen for logged out users, in which case
 * redirect-user-to-workflow-in-progress.global.ts takes care of it.
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
    const {checkUserSessionAndIdentity} = useCheckSessionAndIdentity();

    return await checkUserSessionAndIdentity(to, from);
});
