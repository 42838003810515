import {navigateTo} from '#imports';
import type {RouteLocationNormalized} from 'vue-router';

export function redirectToWhenUnlogged(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    let newRoute = to.meta.redirectToWhenUnlogged as string;
    Object.keys(from.params).forEach((param) => {
        newRoute = newRoute.replace(`[${param}]`, from.params[param] as string);
    });
    return navigateTo(newRoute, {external: true});
}
