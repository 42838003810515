import type {RouteLocationNormalized} from 'vue-router';
import {createError, guestHost, useAuth, useNuxtApp, useRuntimeConfig} from '#imports';
import {redirectToSignIn} from './redirectToSignin';
import {redirectToWhenUnlogged} from '../internals/redirect-to-when-unlogged';
import {safelyNavigateTo} from '#malt/nuxt-utils-module';
import {getQuery} from 'ufo';
import type {RequiredRoles} from '../models/RequiredRoles';
import type {UserIdentityType} from '../models/User';
import {callWithNuxt} from 'nuxt/app';
import type {Logger} from '#malt/nuxt-logger-module';

export function useCheckSessionAndIdentity() {
    const nuxtApp = useNuxtApp();
    const {$pinia} = nuxtApp;

    const baseURL = useRuntimeConfig().app.baseURL;

    const store = useAuth($pinia);
    const host = guestHost();

    function shouldRedirectUserTo(to: RouteLocationNormalized, pathWhereToRedirectUser: string) {
        const currentPath = baseURL + to.path;

        return currentPath !== pathWhereToRedirectUser && (pathWhereToRedirectUser === '/' || !currentPath.startsWith(pathWhereToRedirectUser));
    }

    async function checkUserSessionAndIdentity(to: RouteLocationNormalized, from: RouteLocationNormalized) {
        const currentPath = baseURL + to.path;
        // Cover the error `logger is undefined`
        const logger = nuxtApp.$logger as Logger;

        if (to.meta.enforcedIdentity != null) {
            const enforcedIdentitySpec = to.meta.enforcedIdentity(to);

            let isLogged: boolean, hasValidIdentity: boolean, pathWhereToRedirectUser: string | undefined;
            try {
                ({isLogged, hasValidIdentity, pathWhereToRedirectUser} = await callWithNuxt(nuxtApp, () =>
                    store.tryToEnforceIdentity(enforcedIdentitySpec),
                ));

                if (pathWhereToRedirectUser && shouldRedirectUserTo(to, pathWhereToRedirectUser)) {
                    return callWithNuxt(nuxtApp, () => safelyNavigateTo({path: pathWhereToRedirectUser}, {externalToThisApp: true}));
                }
            } catch (e) {
                logger.error(`Failed to enforce identity, ${e}`, e);
                return callWithNuxt(nuxtApp, redirectToSignIn);
            }

            if (!isLogged) {
                logger.info(`Cannot enforce identity for unlogged user`);
                return callWithNuxt(nuxtApp, redirectToSignIn);
            }

            if (!hasValidIdentity) {
                throw createError({statusCode: 403, statusMessage: 'Invalid identity'});
            }

            if (store.currentIdentityIsUndefined && to.meta.forceQualificationOfUndefinedIdentity && !currentPath.startsWith('/account/membership')) {
                return callWithNuxt(nuxtApp, () => safelyNavigateTo({path: '/account/membership'}, {externalToThisApp: true}));
            }
        }

        if (
            (typeof to.meta.middleware === 'string' && to.meta.middleware === 'auth') ||
            (Array.isArray(to.meta.middleware) && to.meta.middleware.includes('auth'))
        ) {
            try {
                const requiredRoles: RequiredRoles = to.meta.roles ?? [];
                const requiredIdentity: UserIdentityType | 'ANY' = to.meta.identity ?? 'ANY';
                const allowImmersion = to.meta.allowImmersion ?? false;
                const forbidAccessIfCompanyIsSuspended = to.meta.forbidAccessIfCompanyIsSuspended ?? false;

                const {isLogged, pathWhereToRedirectUser} = await callWithNuxt(nuxtApp, store.checkIfLoggedOrIfNeedingRedirect);

                const hasAdminRole = store?.user?.roles?.includes('ROLE_ADMIN');
                const queryParams = getQuery(to.fullPath);
                // FIXME there is an implicit dependency with the implementation of the immersion mode here
                //  it should probably be refactored as an explicit parameter provided by the "mimmersion-mode" middleware
                const isInImmersion = queryParams.identityId !== undefined || queryParams.immersion !== undefined;
                const canBypassIdentityRedirection = allowImmersion && hasAdminRole && isInImmersion;

                if (pathWhereToRedirectUser && shouldRedirectUserTo(to, pathWhereToRedirectUser)) {
                    return callWithNuxt(nuxtApp, () => safelyNavigateTo({path: pathWhereToRedirectUser}, {externalToThisApp: true}));
                }

                if (!isLogged) {
                    if (to.query?.['x-auth']) {
                        // In this case authentication will be handled by the page
                        return;
                    }
                    if (to.meta.redirectToWhenUnlogged) {
                        return redirectToWhenUnlogged(to, from);
                    }
                    return callWithNuxt(nuxtApp, redirectToSignIn);
                } else if (forbidAccessIfCompanyIsSuspended && store.isCompanySuspended()) {
                    return callWithNuxt(nuxtApp, () => safelyNavigateTo('/client-settings/restricted', {externalToThisApp: true}));
                } else if (
                    store.currentIdentityIsUndefined &&
                    to.meta.forceQualificationOfUndefinedIdentity &&
                    !currentPath.startsWith('/account/membership')
                ) {
                    return callWithNuxt(nuxtApp, () => safelyNavigateTo({path: '/account/membership'}, {externalToThisApp: true}));
                } else if (!store.hasEnoughPrivilege(requiredRoles)) {
                    return callWithNuxt(nuxtApp, () => safelyNavigateTo(host, {externalToThisApp: true}));
                } else if (!store.hasCorrectIdentity(requiredIdentity) && !canBypassIdentityRedirection) {
                    if (to.meta.identityRedirect instanceof Function) {
                        const redirectUrl = `${host}${to.meta.identityRedirect(to)}`;
                        return callWithNuxt(nuxtApp, () => safelyNavigateTo(redirectUrl, {externalToThisApp: true}));
                    }
                    const redirectUrl = `${host}${to.meta.identityRedirect || ''}`;
                    return callWithNuxt(nuxtApp, () => safelyNavigateTo(redirectUrl, {externalToThisApp: true}));
                }
            } catch (e) {
                logger.error(`Failed to check if logged or needing redirect ${e}`, e);
                return callWithNuxt(nuxtApp, redirectToSignIn);
            }
        }
    }

    return {checkUserSessionAndIdentity};
}
