import {navigateTo, useRoute, useRuntimeConfig} from '#imports';
import {safelyNavigateTo} from '#malt/nuxt-utils-module';
import {getCurrentServerName} from '../internals/server-utils';

function stripTrailingSlash(path: string) {
    return path.replace(/\/$/, '');
}

/**  In dev mode, no matter what, we rely on independent login page..
 *   But in production we want to redirect to the login page of Malt
 *
 *  Route and router are arguments because it can throw a "nuxt instance unavailable" when doing asynchronous calls before calling composables
 */
export function redirectToSignIn(redirect?: string) {
    const route = useRoute();
    const config = useRuntimeConfig();
    const currentHost = getCurrentServerName();

    const currentRoute = `${stripTrailingSlash(config.app.baseURL)}${route.fullPath}`;
    const query = {redirect: route.name === 'Signin' ? undefined : redirect || currentRoute};

    if (process.dev) {
        const backendUrl = config.public.backendUrl ?? '/';
        const pathPrefix = config.app.baseURL === '/' ? backendUrl : '';

        return navigateTo({path: `${stripTrailingSlash(pathPrefix)}/signin`, query});
    } else {
        return safelyNavigateTo({path: currentHost + '/signin', query}, {externalToThisApp: true});
    }
}
